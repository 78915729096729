'use strict';

var GetIntrinsic = require('get-intrinsic');

var $Object = GetIntrinsic('%Object%');

var RequireObjectCoercible = require('./RequireObjectCoercible'); // https://ecma-international.org/ecma-262/6.0/#sec-toobject


module.exports = function ToObject(value) {
  RequireObjectCoercible(value);
  return $Object(value);
};