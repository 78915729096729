'use strict';

var ToPrimitive = require('./ToPrimitive'); // http://262.ecma-international.org/5.1/#sec-9.3


module.exports = function ToNumber(value) {
  var prim = ToPrimitive(value, Number);

  if (typeof prim !== 'string') {
    return +prim; // eslint-disable-line no-implicit-coercion
  } // eslint-disable-next-line no-control-regex


  var trimmed = prim.replace(/^[ \t\x0b\f\xa0\ufeff\n\r\u2028\u2029\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u0085]+|[ \t\x0b\f\xa0\ufeff\n\r\u2028\u2029\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u0085]+$/g, '');

  if (/^0[ob]|^[+-]0x/.test(trimmed)) {
    return NaN;
  }

  return +trimmed; // eslint-disable-line no-implicit-coercion
};