'use strict';

var GetIntrinsic = require('get-intrinsic');

var $Array = GetIntrinsic('%Array%'); // eslint-disable-next-line global-require

var toStr = !$Array.isArray && require('call-bind/callBound')('Object.prototype.toString'); // https://ecma-international.org/ecma-262/6.0/#sec-isarray


module.exports = $Array.isArray || function IsArray(argument) {
  return toStr(argument) === '[object Array]';
};