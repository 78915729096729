'use strict';

var GetIntrinsic = require('../GetIntrinsic.js');

var $construct = GetIntrinsic('%Reflect.construct%', true);

var DefinePropertyOrThrow = require('./DefinePropertyOrThrow');

try {
  DefinePropertyOrThrow({}, '', {
    '[[Get]]': function Get() {}
  });
} catch (e) {
  // Accessor properties aren't supported
  DefinePropertyOrThrow = null;
} // https://ecma-international.org/ecma-262/6.0/#sec-isconstructor


if (DefinePropertyOrThrow && $construct) {
  var isConstructorMarker = {};
  var badArrayLike = {};
  DefinePropertyOrThrow(badArrayLike, 'length', {
    '[[Get]]': function Get() {
      throw isConstructorMarker;
    },
    '[[Enumerable]]': true
  });

  module.exports = function IsConstructor(argument) {
    try {
      // `Reflect.construct` invokes `IsConstructor(target)` before `Get(args, 'length')`:
      $construct(argument, badArrayLike);
    } catch (err) {
      return err === isConstructorMarker;
    }
  };
} else {
  module.exports = function IsConstructor(argument) {
    // unfortunately there's no way to truly check this without try/catch `new argument` in old environments
    return typeof argument === 'function' && !!argument.prototype;
  };
}