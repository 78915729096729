'use strict';

var toStr = Object.prototype.toString;

var isPrimitive = require('./helpers/isPrimitive');

var isCallable = require('is-callable'); // http://ecma-international.org/ecma-262/5.1/#sec-8.12.8


var ES5internalSlots = {
  '[[DefaultValue]]': function DefaultValue(O) {
    var actualHint;

    if (arguments.length > 1) {
      actualHint = arguments[1];
    } else {
      actualHint = toStr.call(O) === '[object Date]' ? String : Number;
    }

    if (actualHint === String || actualHint === Number) {
      var methods = actualHint === String ? ['toString', 'valueOf'] : ['valueOf', 'toString'];
      var value, i;

      for (i = 0; i < methods.length; ++i) {
        if (isCallable(O[methods[i]])) {
          value = O[methods[i]]();

          if (isPrimitive(value)) {
            return value;
          }
        }
      }

      throw new TypeError('No default value');
    }

    throw new TypeError('invalid [[DefaultValue]] hint supplied');
  }
}; // http://ecma-international.org/ecma-262/5.1/#sec-9.1

module.exports = function ToPrimitive(input) {
  if (isPrimitive(input)) {
    return input;
  }

  if (arguments.length > 1) {
    return ES5internalSlots['[[DefaultValue]]'](input, arguments[1]);
  }

  return ES5internalSlots['[[DefaultValue]]'](input);
};