'use strict';

var abs = require('./abs');

var floor = require('./floor');

var ToNumber = require('./ToNumber');

var $isNaN = require('../helpers/isNaN');

var $isFinite = require('../helpers/isFinite');

var $sign = require('../helpers/sign'); // http://262.ecma-international.org/5.1/#sec-9.4


module.exports = function ToInteger(value) {
  var number = ToNumber(value);

  if ($isNaN(number)) {
    return 0;
  }

  if (number === 0 || !$isFinite(number)) {
    return number;
  }

  return $sign(number) * floor(abs(number));
};