'use strict';

var GetIntrinsic = require('get-intrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var CreateDataProperty = require('./CreateDataProperty');

var IsPropertyKey = require('./IsPropertyKey');

var Type = require('./Type'); // // https://ecma-international.org/ecma-262/6.0/#sec-createdatapropertyorthrow


module.exports = function CreateDataPropertyOrThrow(O, P, V) {
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: Type(O) is not Object');
  }

  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: IsPropertyKey(P) is not true');
  }

  var success = CreateDataProperty(O, P, V);

  if (!success) {
    throw new $TypeError('unable to create data property');
  }

  return success;
};